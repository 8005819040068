<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">领用申请</span>
    </div>

    <div class="title">领用单</div>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item label="领用单号" :span="2">
        {{ detail.consumeOrder }}
      </a-descriptions-item>
      <a-descriptions-item label="领用人">
        {{ detail.consumer }}
      </a-descriptions-item>
      <a-descriptions-item label="领用时间">
        {{ detail.consumeTime }}
      </a-descriptions-item>

      <a-descriptions-item label="申请原因" :span="2">
        {{ detail.reason }}
      </a-descriptions-item>
      <a-descriptions-item label="备注" :span="2">
        {{ detail.remark }}
      </a-descriptions-item>
      <a-descriptions-item label="附件" :span="2">
        <a-space v-if="detail.attachmentList">
          <a
            v-for="item in detail.attachmentList"
            :key="item"
            :href="item.completePath"
            >{{ item.name }}</a
          >
        </a-space>
      </a-descriptions-item>
    </a-descriptions>

    <div class="title">审批信息</div>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item label="审批状态" :span="2">
        <DataDictFinder
          dictType="AssetReviewStatus"
          :dictValue="detail.status"
          iconType="text"
        />
      </a-descriptions-item>

      <a-descriptions-item label="审批人">
        {{ detail.reviewer }}
      </a-descriptions-item>
      <a-descriptions-item label="审批时间">
        {{ detail.reviewTime }}
      </a-descriptions-item>
    </a-descriptions>

    <div class="title">物料清单</div>
    <Goods
      :disabled="true"
      :selected="Array.isArray(detail.materialList) ? detail.materialList : []"
    />

    <div class="center">
      <a-space>
        <a-button @click="cancel">关闭</a-button>
        <template v-if="detail.status === 'REVIEWING'">
          <a-button type="primary" :loading="loading" @click="pass">
            审核通过
          </a-button>
          <a-button type="danger" :loading="loading" @click="reject">
            审核驳回
          </a-button>
        </template>

        <a-button
          type="primary"
          :loading="loading"
          @click="receive"
          v-else-if="detail.status === 'APPROVED'"
        >
          出库
        </a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import { review, accept, fetchDetail } from "../api/use";
import Goods from "./components/goods.vue";

export default {
  components: {
    Goods,
  },

  data() {
    return {
      detail: {},
      loading: false,
    };
  },

  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const { id } = this.$route.query;
      fetchDetail({
        id,
      }).then((res) => {
        if (res) {
          this.detail = Object.freeze(res);
        }
      });
    },
    pass() {
      this.loading = true;
      review({
        id: this.detail.id,
        status: "APPROVED",
      })
        .then(() => {
          this.getDetail();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reject() {
      this.loading = true;
      review({
        id: this.detail.id,
        status: "REJECTED",
      })
        .then(() => {
          this.getDetail();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    receive() {
      this.loading = false;
      accept({
        id: this.detail.id,
      })
        .then(() => {
          this.getDetail();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  margin: 8px 0;
}
.center {
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>



<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;
  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>